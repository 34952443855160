import { decorate, observable, action } from "mobx";
import { create, persist } from "mobx-persist";

class DeviceStore {
    deviceImei = "";
    deviceModel = "";
    deviceIdType = "";
    deviceName = "";
    deviceMake = "";
    deviceRrp = 0;
    deviceError = null;
    deviceActivationDate = "";
    devicePurchaseDate = "";
    deviceType = "";
    devicePlanId = "";
    devicePrice = 0;
    deviceOrderNumber = null;
    deviceOrderNumberFailed = null;
    deviceValidity = null;
    devicePremium = 0;
    deviceDeductibles = [];
    devicePlanPrice = 0;

    updateDeviceDetail = (deviceDetail) => {
        //will not be used in release 1.5
        this.deviceImei = deviceDetail.deviceImei;
        this.deviceRrp = deviceDetail.deviceRrp;
        this.devicePurchaseDate = deviceDetail.devicePurchaseDate;
    };

    updateDeviceValidate = (deviceDetail) => {
        this.deviceType = deviceDetail.deviceType;
        this.devicePlanId = deviceDetail.planId;
        this.devicePrice = deviceDetail.price;
        this.deviceValidity = deviceDetail.validity;
        this.devicePremium = deviceDetail.premium;
        this.deviceDeductibles = deviceDetail.deductibles;
        this.devicePlanPrice = deviceDetail.planPrice;
        this.deviceMake = deviceDetail.deviceMake;
        this.deviceModel = deviceDetail.deviceModel;
        this.deviceIdType = deviceDetail.deviceIdType;
    };

    updateDeviceInfo = (deviceModel, deviceRrp) => {
        this.deviceModel = deviceModel;
        this.deviceRrp = deviceRrp;
    };

    updateDeviceActivationDate = (activationDate) => {
        this.deviceActivationDate = activationDate;
    };

    updateDeviceIMEI = (deviceIMEI) => {
        this.deviceImei = deviceIMEI;
    };

    updateDeviceOrderNumber = (deviceOrderNumber) => {
        this.deviceOrderNumber = deviceOrderNumber;
    };

    updateDeviceOrderNumberFailed = (deviceOrderNumberFailed) => {
        this.deviceOrderNumberFailed = deviceOrderNumberFailed;
    };

    updateError = (errorMessage) => {
        this.deviceError = errorMessage;
    };

    updateCompleteOrder = (deviceDetails) => {
        this.devicePlanPrice = deviceDetails.planPrice;
        this.deviceImei = deviceDetails.deviceId;
    }
}

decorate(DeviceStore, {
    deviceImei: [persist, observable],
    deviceMake: [persist, observable],
    deviceModel: [persist, observable],
    deviceIdType: [persist, observable],
    deviceName: [persist, observable],
    deviceRrp: [persist, observable],
    deviceType: [persist, observable],
    devicePlanId: [persist, observable],
    devicePrice: [persist, observable],
    devicePremium: [persist, observable],
    deviceError: [persist, observable],
    deviceOrderNumber: [persist, observable],
    deviceOrderNumberFailed: [persist, observable],
    deviceActivationDate: [persist, observable],
    devicePurchaseDate: [persist, observable],
    deviceDeductibles: [persist('list'), observable],
    devicePlanPrice: [persist, observable],
    updateDeviceActivationDate: action,
    updateDeviceDetail: action,
    updateDeviceValidate: action,
    updateDeviceInfo: action,
    updateDeviceIMEI: action,
    updateError: action,
    updateCompleteOrder: action,
});

var deviceStore = new DeviceStore();

export default deviceStore;

if (typeof window !== "undefined") {
    const hydrate = create({
        storage: localStorage,
        jsonify: true,
    });
    hydrate("deviceStore", deviceStore);
}
