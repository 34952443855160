import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { navigate, useIntl, FormattedMessage } from "gatsby-plugin-intl";

import api from "../../service/api";
import * as constants from "../../app.constant";
import InputField from "../../components/form/input-field";
import AppLayout from "../../components/shared/AppLayout";
import alertStore from "../../store/alertStore";
import { idPatternTW } from "../../service/validation";
import returnStore from "../../store/returnStore";
import deviceStore from "../../store/deviceStore";
import { sanitizeError, errorKeyNavigateSystemErrorPage } from "../../app.utils";
import errorTranslationKeyStore from "../../store/errorTranslationKeyStore";

const PageReturn = () => {
    const intl = useIntl();
    const messages = intl.messages;
    const errors = {
        customerMobileNumber: messages["return.form.mobileNumberField.required"],
        customerNationalId: messages["return.form.nationalIdField.required"],
    }

    const formik = useFormik({
        initialValues: {
            customerMobileNumber: "",
            customerNationalId: "",
        },
        validationSchema: Yup.object({
            customerMobileNumber: Yup.string()
            .min(10, errors.customerMobileNumber)
            .max(10, errors.customerMobileNumber)
            .required(errors.customerMobileNumber),
            customerNationalId: Yup.string()
                .matches(idPatternTW, errors.customerNationalId)
                .required(errors.customerNationalId),
        }),
        onSubmit: (values, action) => {
            onHandleReturnSignIn();
        },
    });

    const goToVerifyDevicePhotoPage = () => {
        navigate(constants.ROUTES.VERIFY_DEVICE_PHOTO);
    };

    const goToVerifyWearablesPhotoPage = () => {
        navigate(constants.ROUTES.VERIFY_WEARABLES_PHOTO);
    };

    const onHandleIsWearablesNav = (deviceType) => {
        if (deviceType === "Watch") {
            goToVerifyWearablesPhotoPage();
        } else {
            goToVerifyDevicePhotoPage();
        }
    };

    const goToAccountSummaryPage = () => {
        navigate(constants.ROUTES.ACCOUNT_SUMMARY);
    };

    const onHandleReturnSignIn = async () => {
        try {
            const payload = {
                nationalId: formik.values.customerNationalId,
                mobileNumber: formik.values.customerMobileNumber,
            };
            const response = await api.post.returnSignIn(payload);
            localStorage.clear();
            returnStore.updateSubscriptions(response.data);
            const uncompletedSubscription = returnStore.subscriptions.find(subscription => subscription.status === "confirmed");
            const handleHasUncompletedSubscription = () => {
                deviceStore.updateDeviceOrderNumber(uncompletedSubscription.orderNumber);
                onHandleIsWearablesNav(uncompletedSubscription.deviceType);
            };
            uncompletedSubscription ? handleHasUncompletedSubscription() : goToAccountSummaryPage();
        }
        catch(error) {
            const errorTranslationKey = error?.response?.data?.translationKey;

            alertStore.updateAlertMessage(sanitizeError(error));
            if (errorTranslationKey) {
                errorTranslationKeyStore.updateTranslationKey(errorTranslationKey);
                errorKeyNavigateSystemErrorPage(errorTranslationKey);
            }
            formik.setSubmitting(false);
        }
    }

    return (
            <AppLayout hasHeader={true} hasHeaderLogo={true} hasFooter={true} isBackBtn={true} prevUrl={constants.ROUTES.PLANS} isLoading={formik.isSubmitting}>

                <div className="app-page page-return">

                    <div className="return__container container">

                        <form
                            className="return__form"
                            noValidate
                            autoComplete="off"
                            onSubmit={formik.handleSubmit}
                        >

                            <h2 className="return__title">
                                <FormattedMessage id="return.title" />
                            </h2>

                            <InputField
                                label={messages["return.form.mobileNumberField.label"]}
                                placeholder={messages["return.form.mobileNumberField.placeholder"]}
                                name="customerMobileNumber"
                                formik={formik}
                            />

                            <InputField
                                label={messages["return.form.nationalIdField.label"]}
                                placeholder={messages["return.form.nationalIdField.placeholder"]}
                                name="customerNationalId"
                                formik={formik}
                            />

                            <p className="return__description">
                                <FormattedMessage id="return.description" />
                            </p>

                            <p className="btn-container btn-container--center">
                                <button className="btn btn-lg btn-primary" type="submit" disabled={true}>
                                    <FormattedMessage id="return.form.cta" />
                                </button>
                            </p>

                        </form>

                    </div>

                </div>

            </AppLayout>
    );
};

export default PageReturn;
