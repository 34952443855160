import React from "react";

const InputField = ({name, label, type, formik, placeholder, info, disabled}) => {

    const isInvalidClass = () => {
        return formik.touched[name] && formik.errors[name] ? "is-invalid" : "";
    };

    return (
        <div className="form-group">

            <label className="form-group__label" htmlFor={name}>
                {label}
            </label>

            <input
                disabled={disabled}
                className={`form-group__form-control form-control ${isInvalidClass()}`}
                type={type !== null ? type : "text"}
                id={name}
                {...formik.getFieldProps(name)}
                placeholder={placeholder}
            />

            {formik.touched[name] && formik.errors[name] ? (
                <div className="form-group__error invalid-feedback">{formik.errors[name]}</div>
            ) : null}

            {info && <small className="form-group__info form-text">{info}</small>}

        </div>
    );
}

export default InputField;
