export const calculateAge = () => {
    const age = 18;
    const today = new Date();
    const minDate = today;
    minDate.setFullYear(today.getFullYear() - age);
    return minDate;
};

export const mobilePatternTW = /((?=(09))[0-9]{10})$/g;

export const idPatternTW = /^[a-zA-Z][1-2][0-9]{8}$/;

export const isValidDate = d => {
    return d instanceof Date && !isNaN(d);
};
