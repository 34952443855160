import { decorate, observable, action } from "mobx";

class ReturnStore {
    subscriptions = [];

    updateSubscriptions = (subscriptions) => {
        this.subscriptions = subscriptions;
    };
}

decorate(ReturnStore, {
    subscriptions: [observable.ref],
    updateSubscriptions: action,
});
var returnStore = new ReturnStore();
export default returnStore;

